<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click.prevent="updateRoute('messages')">
            <ion-icon :ios="mail" :md="mail"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>Edit Profile</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div class="app-max-width">
        <!-- Avatar -->
        <ion-item button detail @click.prevent="updateRoute('update-profile-photo')">  
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-avatar class="ion-text-center aw-avatar-row-margin">
            <ion-img v-if="authUser.profile_photo_path === null" src="https://artistwavesoftware.s3-us-west-1.amazonaws.com/users/default.jpg" />
            <ion-img v-else :src="authUser.profile_photo_path" />
          </ion-avatar>
          <span style="margin-left:5px;font-size:14px;">
            <ion-text color="medium" v-if="authUser.profile_photo_path === null">Add Profile Picture</ion-text>
            <ion-text color="medium" v-else>Update Profile Picture</ion-text>
          </span>
        </ion-item>

        <!-- About -->
        <ion-item>
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          Bio
        </ion-item>
        <!-- Short Description -->
        <ion-item button detail color="light" @click.prevent="updateRoute('profile-short-description')">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-label>Short Description</ion-label>
        </ion-item>
        <!-- Name -->
        <ion-item>
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          My Account
        </ion-item>
        <!-- Name -->
        <ion-item button detail color="light" @click.prevent="updateRoute('profile-name')">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-label>Name</ion-label>
        </ion-item>
        <!-- Username -->
        <ion-item button detail color="light" @click.prevent="updateRoute('profile-username')">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-label>Username</ion-label>
        </ion-item>
        <!-- Email -->
        <ion-item button detail color="light" @click.prevent="updateRoute('profile-email')">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-label>Email</ion-label>
        </ion-item>
        <!-- Phone -->
        <ion-item button detail color="light" @click.prevent="updateRoute('profile-phone')">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-label>Phone</ion-label>
        </ion-item>          
      </div>
    </ion-content>
    <!-- Footer -->
    <ion-footer>
      <Tabs page="profile-edit" />
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonLabel, IonText, IonAvatar, IonImg, IonTitle } from '@ionic/vue';
import { chevronBack, mail } from 'ionicons/icons';
import { defineComponent, computed } from 'vue';
import { useStore } from "vuex";
import doAuth from '../services/auth';
import Tabs from '../components/Tabs.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ProfileEdit',
  components: {
    IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonLabel, IonText, IonAvatar, IonImg, Tabs, IonTitle
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const router = useRouter();

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return { authUser, store, chevronBack, mail, updateRoute, goBack, router }
  }
});
</script>

<style scoped>
  .aw-avatar-row-margin {
    margin-top:10px!important;
    margin-bottom:10px!important;
  }
</style>
